import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

function ImageList({ list, selectImage, selectedMask, originMask, className }) {
  return (
    <div className={className}>
      <div className="has-text-centered mb-5">
        <h3 className="title is-6 has-text-centered">{originMask.name}</h3>
        <img
          className={cn(styles.image, {
            [styles.selected]: originMask.key === selectedMask.key && originMask.key
          })}
          onClick={() => selectImage(originMask)}
          key={originMask.key}
          alt={originMask.key}
          src={originMask.imageUrl}
          aria-hidden="true"
        />
      </div>
      <h3 className="title is-4 has-text-centered">Otros lentes destacados</h3>
      <div className={styles.imageList}>
        {list.map(image => {
          const { key, imageUrl } = image;

          return (
            <div className="has-text-centered" key={key}>
              <h3 className="title is-7 has-text-centered">{image.name}</h3>
              <img
                className={cn(styles.image, {
                  [styles.selected]: key === selectedMask.key
                })}
                onClick={() => selectImage(image)}
                alt={key}
                src={imageUrl}
                aria-hidden="true"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

ImageList.defaultProps = {
  list: [],
  selectedMask: {},
  originMask: {},
  className: ''
};

ImageList.propTypes = {
  list: arrayOf(shape({ key: string, imageUrl: string })),
  selectImage: func.isRequired,
  selectedMask: shape({ key: string, imageUrl: string }),
  originMask: shape({ key: string, imageUrl: string }),
  className: string
};
export default ImageList;
