import firebase from 'firebase';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCqXILZZLFfR9ioIsyx_WnptpSF_H4B58w',
  authDomain: 'red-de-accesorios.firebaseapp.com',
  databaseURL: 'https://red-de-accesorios.firebaseio.com',
  projectId: 'red-de-accesorios',
  storageBucket: 'red-de-accesorios.appspot.com',
  messagingSenderId: '836118267711',
  appId: '1:836118267711:web:4c3fe5cd1398bc24a0f58a',
  measurementId: 'G-K3WSVGFCV6'
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
