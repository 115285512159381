import React, { useState, useEffect } from 'react';
import 'bulma/css/bulma.css';
import '@fortawesome/fontawesome-free/css/all.css';
import cn from 'classnames';

import ImageList from 'components/ImageList';
import firebase from 'config/firebase';

import { loadModels, maskify, prepareFaceDetector } from 'utils/maskify';

import unselected from 'assets/images/default/ar-camera.png';
import Notification from 'components/Notification';
import styles from './styles.module.scss';

function App() {
  const [selectedFace, setSelectedFace] = useState({});
  const [selectedMask, setSelectedMask] = useState({});
  const [originMask, setOriginMask] = useState({});
  const [maskList, setMaskList] = useState([]);
  const [modelsUp, setModelsUp] = useState(false);
  const [masking, setMasking] = useState(false);
  const [preparing, setPreparing] = useState(true);
  const [notification, setNotification] = useState({});
  const [openInfo, setOpenInfo] = useState(true);

  useEffect(() => {
    const productId = new URLSearchParams(window.location.search).get('id');

    const db = firebase.firestore();

    // Get param option

    if (productId) {
      db.collection('products')
        .where('externalId', '==', productId)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            setNotification({
              message: `El probador para el producto ${productId} aún no está disponible, muy pronto lo estará, mientras tanto puedes probarte algunos de nuestros otros lentes destacados.`,
              className: 'is-warning'
            });
          }
          querySnapshot.forEach(document => {
            const { externalId: key, imageUrl, name } = document.data();
            setSelectedMask({
              key,
              imageUrl,
              name
            });
            setOriginMask({
              key,
              imageUrl,
              name
            });
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('Error getting param document: ', error);
        });

      // Get list of options

      db.collection('products')
        .limit(6)
        .where('externalId', '!=', productId)
        .where('starred', '==', '1')
        .get()
        .then(querySnapshot => {
          const responseList = [];
          querySnapshot.forEach(document => {
            const { externalId: key, imageUrl, name } = document.data();
            responseList.push({
              key,
              imageUrl,
              name
            });
          });
          setMaskList(responseList);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('Error getting documents: ', error);
        });
    }

    loadModels()
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('Models up');
        setModelsUp(true);
        prepareFaceDetector().then(() => {
          // eslint-disable-next-line no-console
          console.log('Detector prepared!');
          setPreparing(false);
        });
      })
      .catch(() => {
        setModelsUp('false');
      });
  }, []);

  useEffect(() => {
    if (modelsUp && selectedMask.imageUrl && selectedFace.imageUrl) {
      setMasking(true);
      const imageContainer = document.querySelector(`.${styles.imageWrapper}`);
      const originalImage = document.querySelector(`.${styles.previewImage}`);
      setTimeout(async () => {
        const masked = await maskify(
          selectedMask.imageUrl,
          imageContainer,
          originalImage
        );
        if (masked === 'no-face')
          setNotification({
            message:
              'Error al detectar, prueba con otra foto: Tu rostro no debe estar ni muy cerca ni muy lejos, debe tener buena iluminación y no debe estar demasiado inclinado.',
            className: 'is-danger'
          });
        if (masked === 'masked') {
          setNotification({});
        }
        setMasking(false);
      }, 200);
    }
  }, [selectedFace, selectedMask, modelsUp]);

  const cleanOverlay = () => {
    const elem = document.querySelector('#mask-overlay');
    if (elem) elem.parentNode.removeChild(elem);
  };

  const handleDelete = () => {
    cleanOverlay();
    setSelectedFace({});
    setNotification({});
  };

  const handleSelect = (image, method) => {
    if (image.key === selectedFace.key || image.key === selectedMask.key) return;
    cleanOverlay();
    method(image);
  };

  const handleFile = files => {
    if (files.length) {
      cleanOverlay();
      setSelectedFace({
        imageUrl: URL.createObjectURL(files[0]),
        key: 'local-file'
      });
    }
  };

  return (
    <div className={styles.mainWrapper}>
      <Notification
        message={notification.message}
        className={notification.className}
        onDelete={() => setNotification({})}
      />
      <div className={styles.appContainer}>
        <div className={styles.previewContainer}>
          {/* eslint-disable jsx-a11y/label-has-associated-control */}
          {!!selectedFace?.imageUrl || (
            <label className={cn('file-label', styles.unselected)}>
              <input
                className="file-input"
                type="file"
                name="photo"
                onChange={e => handleFile(e.target.files)}
              />
              {/* <span className="">hola</span> */}
              <figure className={styles.imageWrapper}>
                {/* <h2 className="title is-2 has-text-centered">Sube una foto!</h2> */}
                <img className={styles.previewImage} alt="camara" src={unselected} />
              </figure>
            </label>
          )}

          {selectedFace?.imageUrl && (
            <figure className={styles.imageWrapper}>
              <button
                type="button"
                aria-label="delete"
                className={`delete is-large ${styles.delete}`}
                onClick={handleDelete}
              />
              <img
                className={styles.previewImage}
                alt="face"
                src={selectedFace.imageUrl}
              />
            </figure>
          )}

          {masking && (
            <div className={styles.loaderContainer}>
              <div className={styles.loader} />
            </div>
          )}
        </div>
        {!preparing && (
          <ImageList
            list={maskList}
            selectImage={mask => handleSelect(mask, setSelectedMask)}
            originMask={originMask}
            selectedMask={selectedMask}
            className={styles.maskContainer}
          />
        )}
      </div>
      {openInfo && (
        <div className={`notification is-info is-light ${styles.information}`}>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => setOpenInfo(false)}
          />
          El tamaño del marco en el probador virtual es una{' '}
          <strong>aproximación</strong>, puede variar ligeramente con el tamaño real.
        </div>
      )}
      {preparing && (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
        </div>
      )}
    </div>
  );
}

export default App;
